<template>
  <div id="members-top-menu">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed v-bind="attrs" v-on="on" class="hide-from-print">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list class="hide-from-print">
        <v-list-item @click="openPrintDialog">
          <v-list-item-title>
            <v-icon>mdi-printer</v-icon> Ispiši
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="downloadAsExcel">
          <v-list-item-title>
            <v-icon>mdi-microsoft-excel</v-icon> Preuzmi kao Excel datoteku
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <PrintableMembersList :members="tableItems" />
  </div>
</template>

<script>
import PrintableMembersList from "./PrintableMembersList.vue";
import * as XLSX from "xlsx";

export default {
  name: "TopMenu",

  props: ["tableItems", "members"],

  components: {
    PrintableMembersList,
  },

  methods: {
    async openPrintDialog() {
      await this.$nextTick();
      window.print();
    },

    downloadAsExcel() {
      const table = document.getElementById("print-members");

      const workSheet = XLSX.utils.table_to_sheet(table, { raw: true });
      const workBook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workBook, workSheet, "data");
      XLSX.writeFile(workBook, "DPHapp-" + Date.now() + ".xlsx");
    },
  },
};
</script>

<style lang="scss" scoped>
#members-top-menu {
  display: inline;

  button {
    margin: 0 auto;
  }
}
</style>
