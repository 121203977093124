<template>
  <v-card id="member-search" class="pa-2 main-card" outlined>
    <h3>Pretraga članova</h3>
    <v-divider></v-divider>

    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" lg="4" sm="6">
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="firstName"
                    :rules="nameRules"
                    label="Ime"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="lastName"
                    :rules="nameRules"
                    label="Prezime"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="membershipId"
                    :rules="idRules"
                    label="Br. člana"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <div class="search-member-action">
              <v-btn
                :disabled="!valid"
                v-if="!loading"
                color="success"
                class="mr-4"
                @click="searchMember"
              >
                Traži
              </v-btn>

              <div v-else>
                <v-progress-circular
                  indeterminate
                  color="#1358a3"
                ></v-progress-circular>
              </div>
            </div>
          </v-form>
        </v-col>

        <v-col cols="12" lg="4" sm="6" class="member-search-results">
          <div v-if="!loading && result">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Ime i prezime</td>
                    <td>{{ result.FirstName }} {{ result.LastName }}</td>
                  </tr>

                  <tr>
                    <td>Status člana</td>
                    <td :class="result.Active__c ? 'active' : 'inactive'">
                      {{ result.Active__c ? "Aktivan" : "Neaktivan" }}
                    </td>
                  </tr>

                  <tr>
                    <td>Baza člana</td>
                    <td>
                      {{ result.Primary_Affiliate__r.Name }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div class="clearfix">
              <RenewMembershipButton
                v-if="!result.Active__c"
                :member="result"
                membershipType="single"
                class="renew-btn"
              />
            </div>

            <div class="clearfix">
              <RenewMembershipButton
                v-if="!result.Active__c && result.Household_Role__c == 'Main'"
                :member="result"
                membershipType="family"
                class="renew-btn"
              />
            </div>
          </div>

          <p v-show="error" class="error-text">{{ error }}</p>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import apiRequest from "../../helpers/apiRequest";
import RenewMembershipButton from "../membership/RenewMembershipButton.vue";

export default {
  name: "SearchMember",

  components: {
    RenewMembershipButton,
  },

  data: () => ({
    valid: false,
    firstName: "",
    lastName: "",
    nameRules: [(v) => !!v || "Obavezno polje"],
    membershipId: "",
    idRules: [
      (v) => !!v || "Obavezno polje",
      (v) => /^\d+$/.test(v) || "Neispravan broj člana",
    ],
    loading: false,
    error: false,
    result: null,
  }),

  methods: {
    async searchMember() {
      this.loading = true;
      this.error = false;
      this.result = null;

      try {
        this.result = await apiRequest("get", "members/search", {
          firstName: this.firstName,
          lastName: this.lastName,
          membershipId: this.membershipId,
        });
      } catch (error) {
        if (error.response.data.message) {
          this.error = error.response.data.message;
        } else {
          this.error = error.message;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-member-action {
  float: right;
}

.member-search-results {
  padding: 30px 0 0 30px !important;

  tr td:nth-child(2) {
    font-weight: 600;

    &.active {
      color: #27ae60;
    }

    &.inactive {
      color: #ce3137;
    }
  }

  .renew-btn {
    float: right;
    margin-top: 16px;
  }
}
</style>
