<template>
  <div id="print-members" v-if="members">
    <table>
      <tr>
        <th>Ime</th>
        <th>Prezime</th>
        <th>Email</th>
        <th>ID Člana</th>
        <th>Status</th>
        <th>Datum rođenja</th>
        <th>Telefon</th>
        <th>Zadnja g. članstva</th>
      </tr>
      <tr v-for="member in members" :key="member.Member_ID__c">
        <td>{{ member.FirstName }}</td>
        <td>{{ member.LastName }}</td>
        <td>{{ member.npe01__HomeEmail__c }}</td>
        <td>{{ member.Member_ID__c }}</td>
        <td>{{ member.Active__c ? "Aktivan" : "Neaktivan" }}</td>
        <td>{{ formatDate(member.Birthdate) }}</td>
        <td>{{ member.MobilePhone }}</td>
        <td>{{ member.Last_Membership_Year__c }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import mixin from "../../mixins/mixin";

export default {
  mixins: [mixin],

  name: "PrintableMembersList",

  props: ["members"],
};
</script>

<style lang="scss" scoped>
#print-members {
  display: none;
}

th,
td {
  text-align: left;
  padding: 0 10px;
}

@media print {
  #print-members {
    display: block;
  }
}
</style>

<style lang="scss">
@media print {
  nav,
  header,
  #member-search,
  .members-table,
  .hide-from-print {
    display: none !important;
  }

  .v-main {
    padding: 0 !important;
  }

  #main-footer {
    height: auto !important;
  }
}
</style>
